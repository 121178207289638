import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import PhoneInput from 'react-phone-input-2'

import { AUTOCOMPLETE_OFF, TEXT_FIELD_TYPE } from 'constants/forms.constants'

import { FieldError } from '@elo-kit/components/elo-ui/field-error/FieldError'
import { InfoTooltip } from '@elo-kit/components/info-tooltip/InfoTooltip'

import { ELOPAGE_PREFIX } from 'constants/domains.constants'

import 'react-phone-input-2/lib/style.css'

import '@elo-kit/styles/text-field.styles.scss'

const propTypes = {
  autocomplete: PropTypes.string,
  className: PropTypes.string,
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
  }),
  form: PropTypes.shape({
    touched: PropTypes.shape({}),
    errors: PropTypes.shape({}),
  }),
  inputClassName: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  type: PropTypes.oneOf(Object.values(TEXT_FIELD_TYPE)),
  withHidePassword: PropTypes.bool,
  lowercased: PropTypes.bool,
}

const defaultProps = {
  autocomplete: AUTOCOMPLETE_OFF,
  disabled: false,
  type: TEXT_FIELD_TYPE.text,
  withHidePassword: false,
  lowercased: false,
}

const FTextField = (props) => {
  const {
    autocomplete,
    className,
    disabled,
    field: { name, onChange, value },
    form: { touched, errors },
    id,
    handleBlur: customBlurHandler,
    inputClassName,
    label,
    onBlur,
    password,
    placeholder,
    phoneNumber,
    readOnly,
    required,
    tooltipId,
    lowercased,
    tooltipTitle,
    type,
    withHidePassword,
    prefix,
    errorId,
    outlined,
    skipValidation,
    showDomainValue,
    setFieldValue,
    setFieldTouched,
    prefixNormalField, // to avoid set props to input
    validateOnHasContent = false,
    ...restProps
  } = props
  const [hidePassword, setHidePassword] = useState(true)
  const toggleHidePassword = () => setHidePassword(!hidePassword)

  const textFieldChange = (event) => {
    const { form, field, handleBlur = () => {} } = props
    const { name, value } = field

    const trimmedValue = value && value?.trim()

    if (trimmedValue !== value) {
      form.setFieldValue(name, trimmedValue)
    }

    field.onBlur(event)
    handleBlur(name, trimmedValue)
  }

  const isInvalid = errors[name] && (touched[name] || (value && validateOnHasContent))

  const fieldClassNames = classNames('field text-field', className, {
    'field--error': isInvalid && !skipValidation,
    'field--readonly': readOnly,
    'field--required': required,
  })

  const fieldControlClassNames = classNames(inputClassName, {
    'text-field__control--error': isInvalid && !skipValidation && !phoneNumber,
    'text-field__control--lowercased': lowercased,
    'field--disabled': disabled,
    'field__control text-field__control': !phoneNumber,
  })

  const eyeClasses = classNames('password-icon', {
    'fas fa-eye-slash': hidePassword,
    'fas fa-eye': !hidePassword,
  })

  const handleBlur = onBlur ? (event) => onBlur({ [name]: value, event }) : textFieldChange

  let fieldType = type

  if (withHidePassword) {
    fieldType = hidePassword ? type : 'text'
  }

  const tooltipTitles = {
    company_name: I18n.t('react.cabinet.help_icon.company_name.title'),
    registration_location: I18n.t('react.cabinet.help_icon.registration_location.title'),
  }

  const tooltipContent = {
    internal_name: I18n.t('react.cabinet.help_icon.internal_name.content'),
    company_name: I18n.t('react.cabinet.help_icon.company_name.content'),
    tax_number: I18n.t('react.cabinet.help_icon.tax_number.content'),
    registration_location: I18n.t('react.cabinet.help_icon.registration_location.content'),
    welcome_title: I18n.t('react.cabinet.help_icon.welcome_title.content'),
    nickname: I18n.t('react.cabinet.help_icon.nickname.content'),
    tracify_cs_id: I18n.t('react.cabinet.help_icon.tracify_cs_id.content'),
    tracify_api_key: I18n.t('react.cabinet.help_icon.tracify_api_key.content'),
  }

  return (
    <div className={fieldClassNames}>
      {label && (
        <label className='field__label' htmlFor={name}>
          <span>{label}</span>
          {tooltipId && (
            <InfoTooltip
              id={`${name}${id ? `_${id}` : ''}_popover`}
              title={tooltipTitle ? tooltipTitles[tooltipId] : ''}
              body={tooltipContent[tooltipId]}
            />
          )}
        </label>
      )}
      {isInvalid && !skipValidation && name !== 'phoneNumber' && (
        <FieldError id={errorId} errorText={errors[name]} outlined={outlined} />
      )}
      {name === 'phoneNumber' && errors.phoneNumber && touched.phoneNumber && (
        <FieldError id={errorId} errorText={errors[name]} outlined={outlined} />
      )}
      <div className='text-field__control-wrapper'>
        {prefix && <div className='text-field__prefix input-group-prepend'>{prefix}</div>}

        {phoneNumber ? (
          <PhoneInput
            value={value}
            country={'de'}
            onChange={(value) => {
              setFieldValue('phoneNumber', value)
              setFieldTouched('phoneNumber', true)
            }}
            containerClass='input-container'
            inputClass='phone-input'
            buttonClass='country-selector'
            className={fieldControlClassNames}
            excludeCountries={['ru', 'ke']}
          />
        ) : (
          <>
            <input
              autoComplete={autocomplete}
              className={fieldControlClassNames}
              id={name}
              onBlur={handleBlur}
              {...{
                ...restProps,
                name,
                onChange,
                placeholder,
                readOnly,
                type: fieldType,
                value: value || '',
              }}
            />
            {showDomainValue && <p className='domain-text'>{ELOPAGE_PREFIX}</p>}
          </>
        )}
        {withHidePassword && <i className={eyeClasses} onClick={toggleHidePassword} />}
      </div>
    </div>
  )
}

FTextField.displayName = 'FTextField'
FTextField.propTypes = propTypes
FTextField.defaultProps = defaultProps

export default FTextField
